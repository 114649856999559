<script setup lang="ts">
import {
  executeActions,
  type QueuedGlobalSnackbarNotification,
  removeSnackbar,
  snackbarStore,
} from '@/utils/client/store/snackbarStore'
import { useStore } from '@nanostores/vue'
import BaseSnackbar from './BaseSnackbar.vue'

const props = withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    topOffset?: number
  }>(),
  { darkMode: 'auto', topOffset: 0 },
)

const snackbarArray = useStore(snackbarStore)

function calculateSnackbarPosition(snackbarIndex: number): string {
  const currentSnackbarArrayIndexes = snackbarArray.value.map((snackbar) => snackbar.originalIndex)
  const actualIndex = currentSnackbarArrayIndexes.indexOf(snackbarIndex)
  // 49 is the height of snackbar plus margin between snackbars
  // each subsequent snackbar is height plus margin of a snackbar from the top of the viewport plus initial gap (16px)
  return `${(snackbarArray.value.length - actualIndex) * (54 + 6) - 36 + props.topOffset}px`
}

function onClick(snackbar: QueuedGlobalSnackbarNotification): void {
  if (!snackbar.dismissable) {
    return
  }
  removeSnackbar(snackbar.uid)
}
</script>

<template>
  <div :class="['fixed w-full flex flex-col items-center pointer-events-none start-0 isolate z-[9999]']" role="status">
    <transition-group name="snackbar-transition">
      <BaseSnackbar
        v-for="(snackbar, i) in snackbarArray"
        :key="snackbar.uid"
        :data-testid="`snackbar${i}`"
        :dark-mode="darkMode"
        class="overflow-visible fixed transition-all mb-2"
        :uid="snackbar.uid"
        :message="snackbar.message"
        :notification-type="snackbar.notificationType"
        :icon-name="snackbar.iconName"
        :action-text="snackbar.actionText"
        :timeout="snackbar.timeout"
        :style="{ top: calculateSnackbarPosition(snackbar.originalIndex) }"
        :x-close-icon="snackbar.persist"
        @action-text-click="executeActions(snackbar.actionTextActions, $event)"
        @click="onClick(snackbar)"
      />
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
// Animation functions follows https://material.io/design/motion/speed.html#easing
.snackbar-transition-enter-active {
  /* Ease in for entering */
  animation: fadeInTop 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.snackbar-transition-leave-active,
.snackbar-transition-leave-to {
  /* Ease out for leaving */
  animation: fadeOutTop 0.3s cubic-bezier(0.4, 0, 1, 1);
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutTop {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
