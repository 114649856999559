<script setup lang="ts">
import BaseButton from '@/components/library/BaseButton.vue'
import BaseDivider from '@/components/library/BaseDivider.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'
import { __ } from '@/utils/shared/intl'

enum NavigationSource {
  Home = 'Home',
  External = 'External',
}

withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    title?: string
  }>(),
  {
    darkMode: true,
  },
)

const referrer = document.referrer
let navigationSource: NavigationSource | null = null

// If the history length is 1 (means that the current page was
// open through a link in a new tab), redirect to dashboard
if (window.history.length === 1) {
  navigationSource = NavigationSource.External
}
// If there's no referrer, assume external.
if (referrer == null || referrer.length === 0) {
  navigationSource = NavigationSource.External
} else {
  const referrerUrl = new URL(document.referrer)
  // If the referrer does not match the home page, assume external.
  if (referrerUrl.hostname === window.location.hostname && referrerUrl.pathname === '/') {
    navigationSource = NavigationSource.Home
  } else {
    navigationSource = NavigationSource.External
  }
}
</script>

<template>
  <div class="flex items-center justify-center h-full">
    <BaseButton
      href="/"
      component="a"
      :class="[
        'h-10',
        'flex',
        'items-center',
        'justify-center',
        'text-lg leading-[30px]',
        {
          'text-white': darkMode === true,
          'text-black': darkMode === false,
          'text-white dark:text-black': darkMode === 'auto',
        },
        // Hover
        {
          'hover:text-white/80': darkMode === true,
          'hover:text-black/80': darkMode === false,
          'hover:text-white/80 dark:hover:text-black/80': darkMode === 'auto',
        },
        // Active
        {
          'active:text-white/60': darkMode === true,
          'active:text-black/60': darkMode === false,
          'active:text-white/60 dark:active:text-black/60': darkMode === 'auto',
        },
        // Focus
        'outline-none',
        'rounded-xl',
        'focus-visible:ring-2',
        'focus-visible:ring-offset-2',
        {
          'focus-visible:ring-yellow-500': darkMode === true,
          'focus-visible:ring-[#9466E8]': darkMode === false,
          'focus-visible:ring-yellow-500 dark:focus-visible:ring-[#9466E8]': darkMode === 'auto',
        },
        'focus-visible:ring-offset-gray-50',
      ]"
      :dark-mode="true"
    >
      <template v-if="navigationSource === NavigationSource.Home">
        <BaseIcon dir="auto" class="me-2" name="interface_arrow_left" :size="24" />
        {{ __('Home') }}
      </template>
      <template v-else>
        <BaseIcon class="me-2" name="home_outline" :size="24" />
        {{ __('Home') }}
      </template>
    </BaseButton>
    <template v-if="title">
      <BaseDivider class="!h-6 mx-3 hidden md:block" orientation="vertical" :dark-mode="darkMode" />
      <p
        :class="[
          'hidden',
          'md:block',
          'text-lg',
          'leading-[30px]',
          {
            'text-white': darkMode === true,
            'text-black': darkMode === false,
            'text-white dark:text-black': darkMode === 'auto',
          },
        ]"
      >
        {{ title }}
      </p>
    </template>
  </div>
</template>
