<script setup>
import { __ } from '@/utils/shared/intl'
</script>
<template>
  <div class="flex-row gap-2 items-start relative hidden sm:flex select-none">
    <!-- Padlet logo-->
    <div class="pt-2 flex items-center justify-center">
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.8343 29.0426L12.1758 29.8695C11.3592 30.0541 10.5419 29.5632 10.3214 28.7555L7.87207 19.7871L14.9 3.44819C15.2529 2.62795 16.4158 2.62795 16.7686 3.44819L23.7966 19.7871L21.3473 28.7555C21.1267 29.5632 20.3095 30.0541 19.4929 29.8695L15.8343 29.0426ZM15.8343 4.16845L16.4031 20.944L23.2279 19.892L16.4031 22.101L15.8343 28.4641L15.2656 22.101L8.4408 19.892L15.2656 20.944L15.8343 4.16845Z"
          fill="#FFBC00"
        />
        <mask
          id="mask0_813_4640"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="7"
          y="2"
          width="17"
          height="28"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8343 29.0426L12.1758 29.8695C11.3592 30.0541 10.5419 29.5632 10.3214 28.7555L7.87207 19.7871L14.9 3.44819C15.2529 2.62795 16.4158 2.62795 16.7686 3.44819L23.7966 19.7871L21.3473 28.7555C21.1267 29.5632 20.3095 30.0541 19.4929 29.8695L15.8343 29.0426ZM15.8343 4.16845L16.4031 20.944L23.2279 19.892L16.4031 22.101L15.8343 28.4641L15.2656 22.101L8.4408 19.892L15.2656 20.944L15.8343 4.16845Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_813_4640)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8281 21.491V29.8465L21.3061 31.0614L24.2385 19.6797L15.8281 21.491Z"
            fill="#FF843C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8226 0.430664V21.672L7.41113 19.6775L15.8226 0.430664Z"
            fill="#63DA46"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.87 29.2164L15.7079 21.6128L7.47363 19.6797L10.2936 30.2936L11.6718 30.7106L15.87 29.2164Z"
            fill="#9466E8"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.43108 25.5439L6.76261 19.6474L8.99977 14.5647L0.93175 10.1126C0.377719 9.80686 -0.228227 10.4225 0.0862298 10.9716L8.43108 25.5439Z"
          fill="#3992DE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.7969 15.925L33.3269 17.1286C33.7378 17.324 34.1586 16.8791 33.9405 16.4797L31.7401 12.4492L30.7969 15.925Z"
          fill="#C1BDC1"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.6289 14.6248L24.9069 19.7936L23.2234 25.5515L31.5695 12.457H27.6474L22.6289 14.6248Z"
          fill="#FF4081"
        />
      </svg>
    </div>
    <div class="flex flex-col items-end justify-start">
      <h1 class="font-bagel text-3xl whitespace-nowrap tracking-[0.02em] leading-[50px] text-[rgba(17,17,17,0.9)]">
        {{ __('Padlet TA') }}
      </h1>

      <p class="text-black/80 font-sans font-bold text-sm leading-[12px] -mt-0.5 pe-1">
        {{ __('BETA') }}
      </p>
    </div>
  </div>
</template>
