<script setup lang="ts">
import { cancel, confirm, dialogStore, discard } from '@/utils/client/store/globalDialogStore'
import { useStore } from '@nanostores/vue'
import BaseDialog from './BaseDialog.vue'

const props = withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
  }>(),
  { darkMode: 'auto' },
)

const dialog = useStore(dialogStore)
</script>

<template>
  <Transition
    enter-active-class="transition-opacity duration-250"
    leave-active-class="transition-opacity duration-150"
    enter-from-class="opacity-0 ease-out"
    leave-to-class="opacity-0 ease-in"
  >
    <div v-if="dialog.isOpen" class="fixed inset-0 flex items-center justify-center z-[9999]">
      <div class="fixed inset-0 bg-black/50" @click="cancel" />

      <BaseDialog
        :dark-mode="darkMode"
        :title="dialog.config.title"
        :body="dialog.config.body"
        :layout-variant="dialog.config.layoutVariant"
        :icon-name="dialog.config.iconName"
        :confirm-button-text="dialog.config.confirmButtonText"
        :cancel-button-text="dialog.config.cancelButtonText"
        :discard-button-text="dialog.config.discardButtonText"
        :button-scheme="dialog.config.buttonScheme"
        :custom-body-classes="dialog.config.customBodyClasses"
        :force-full-width-buttons="dialog.config.forceFullWidthButtons"
        :should-fade-in="dialog.config.shouldFadeIn"
        :max-width="dialog.config.maxWidth"
        class="relative z-10"
        @confirm="confirm"
        @cancel="cancel"
        @discard="discard"
      />
    </div>
  </Transition>
</template>
