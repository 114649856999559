<script setup lang="ts">
import HomeTopbarLeft from '@/components/HomeTopbarLeft.vue'
import LayoutTopbarLeft from '@/components/LayoutTopbarLeft.vue'
import LayoutTopbarRight from '@/components/LayoutTopbarRight.vue'
import GlobalDialog from '@/components/library/GlobalDialog.vue'
import GlobalSnackbar from '@/components/library/GlobalSnackbar.vue'
import { ref } from 'vue'

interface Props {
  title?: string
  isLandingPage?: boolean
  loadedLocale: string
}

withDefaults(defineProps<Props>(), {
  title: undefined,
  isLandingPage: false,
  loadedLocale: 'en_US',
})

const currentPath = ref(window.location.pathname)
</script>

<template>
  <template v-if="currentPath === '/'">
    <GlobalDialog />
    <GlobalSnackbar />
    <slot />
  </template>
  <template v-else>
    <GlobalSnackbar />
    <main id="main" class="w-full h-full flex flex-col">
      <GlobalDialog />
      <template v-if="!isLandingPage">
        <div class="my-4 grid grid-cols-3 mx-6 grid-auto-rows-min">
          <div class="col-span-2 flex gap-2">
            <template v-if="currentPath !== '/'">
              <LayoutTopbarLeft :dark-mode="currentPath !== '/history'" :title="title" />
            </template>
            <template v-else>
              <HomeTopbarLeft />
            </template>
          </div>
          <div
            :class="[
              currentPath !== '/' ? 'gap-3' : 'gap-4',
              currentPath === '/history' && 'h-14',
              'col-span-1 flex justify-end items-center',
            ]"
          >
            <LayoutTopbarRight :current-path="currentPath" :loaded-locale="loadedLocale" />
          </div>
        </div>
      </template>
      <slot />
    </main>
  </template>
</template>
