<script setup lang="ts">
import { fetchDuplicatePrompt } from '@/api/promptHistory'
import AuthButton from '@/components/AuthButton.vue'
import PromptHistoryButton from '@/components/PromptHistoryButton.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'
import BaseIconButton from '@/components/library/BaseIconButton.vue'
import BasePopover, { BasePopoverBackdropStyle } from '@/components/library/BasePopover.vue'
import BaseRows, { type BaseRowsRow } from '@/components/library/BaseRows.vue'
import { trackEvent } from '@/utils/client/analytics'
import { copyToClipboard } from '@/utils/client/clipboard'
import { useGenerateStatusStore } from '@/utils/client/store/generateStatusStore'
import { openDialog } from '@/utils/client/store/globalDialogStore'
import { useSessionDataStore } from '@/utils/client/store/sessionDataStore'
import { SnackbarNotificationType, setSnackbar } from '@/utils/client/store/snackbarStore'
import { useUrlHashStore } from '@/utils/client/store/urlHashStore'
import { __ } from '@/utils/shared/intl'
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/vue'
import { computed, onMounted, ref } from 'vue'

enum ActionType {
  Share = 'Share',
  Duplicate = 'Duplicate',
  History = 'History',
  Auth = 'Auth',
}

const { isReadyToExport } = useGenerateStatusStore()
const { urlHashState } = useUrlHashStore()
const hasPromptId = ref(false)

const props = defineProps<{
  currentPath: string
  loadedLocale: string
}>()

const menuButtonRef = ref<HTMLButtonElement | null>(null)
const menuPopoverRef = ref<HTMLDivElement | null>(null)
const { floatingStyles } = useFloating(menuButtonRef, menuPopoverRef, {
  placement: 'bottom-end',
  whileElementsMounted: autoUpdate,
  middleware: [
    offset({
      mainAxis: 8,
    }),
    flip(),
  ],
})

const promptHistoryButtonRef = ref<InstanceType<typeof PromptHistoryButton> | null>(null)
const authButtonRef = ref<InstanceType<typeof AuthButton> | null>(null)

function updateHasPromptId() {
  const pathComponents = window.location.pathname.split('/')
  if (pathComponents.length > 2) {
    hasPromptId.value = true
  } else {
    hasPromptId.value = false
  }
}

const supportsShare = true
const supportsDuplicate = computed<boolean>(() => {
  const toolName = urlHashState.value.toolName
  if (toolName === 'short-link') return false
  return true
})

const canShare = computed(() => {
  return supportsShare && hasPromptId.value && isReadyToExport.value
})

const canDuplicate = computed(() => {
  return supportsDuplicate.value && hasPromptId.value && isReadyToExport.value
})

const { isLoggedIn } = useSessionDataStore()

const menuRows = computed<BaseRowsRow[]>(() => {
  const rows: BaseRowsRow[] = []

  if (canShare.value) {
    rows.push({
      id: ActionType.Share,
      text: __('Share'),
      icon: 'interface_share',
      payload: ActionType.Share,
    })
  }

  if (canDuplicate.value) {
    rows.push({
      id: ActionType.Duplicate,
      text: __('Duplicate'),
      icon: 'interface_duplicate',
      payload: ActionType.Duplicate,
    })
  }

  if (props.currentPath !== '/history') {
    rows.push({
      id: ActionType.History,
      text: __('History'),
      icon: 'history_outline',
      payload: ActionType.History,
    })
  }

  if (isLoggedIn.value) {
    rows.push({
      id: ActionType.Auth,
      text: __('Account'),
      icon: 'interface_smiley',
      payload: ActionType.Auth,
    })
  } else {
    rows.push({
      id: ActionType.Auth,
      text: __('Login'),
      icon: 'login_outline',
      payload: ActionType.Auth,
    })
    rows.push({
      id: ActionType.Auth,
      text: __('Sign up'),
      icon: 'signup_outline',
      payload: ActionType.Auth,
    })
  }

  return rows
})

const popoverRef = ref<InstanceType<typeof BasePopover> | null>(null)
const isHistoryOrAuthTriggered = ref(false)
const handleMenuSelectRow = (_event: Event, payload: ActionType): void => {
  switch (payload) {
    case ActionType.Share:
      share()
      break
    case ActionType.Duplicate:
      duplicate()
      break
    case ActionType.History:
      isHistoryOrAuthTriggered.value = true
      promptHistoryButtonRef.value?.triggerClick()
      break
    case ActionType.Auth:
      isHistoryOrAuthTriggered.value = true
      authButtonRef.value?.triggerClick()
      break
  }

  popoverRef.value?.hidePopover()
}

function share() {
  trackEvent('Opened "Share" dialog')
  openDialog({
    iconName: 'interface_share',
    title: __('Share'),
    body: __(
      'Share a view-only version of your prompt and output. Please make sure there is no private information before sharing.',
    ),
    confirmButtonText: __('Copy link'),
    cancelButtonText: __('Cancel'),
    forceFullWidthButtons: true,
    maxWidth: 336, //w-[332px] + 4 px border,
    afterConfirmActions: [
      () => {
        const url = window.location.href
        copyToClipboard(url)
        setSnackbar({
          message: __('Link copied to clipboard'),
          notificationType: SnackbarNotificationType.success,
        })
        trackEvent('Copied task URL from "Share" dialog', { url })
      },
    ],
  })
}

async function duplicate() {
  const hashid = window.location.pathname.split('/').pop()
  if (!hashid) {
    setSnackbar({
      message: __('Cannot duplicate: no prompt selected'),
      notificationType: SnackbarNotificationType.error,
    })
    return
  }

  trackEvent('Opened "Duplicate" dialog')
  openDialog({
    iconName: 'interface_duplicate',
    title: __('Duplicate'),
    body: __('Copy your own version of this tool. Tweak and tune the prompt to make it your own.'),
    confirmButtonText: __('Duplicate'),
    cancelButtonText: __('Cancel'),
    forceFullWidthButtons: true,
    maxWidth: 336, //w-[332px] + 4 px border,
    afterConfirmActions: [
      async () => {
        try {
          const response = await fetchDuplicatePrompt(hashid)
          const newHashid = response.data.hashid
          const toolName = urlHashState.value.toolName
          const newUrl = `/${toolName}/${newHashid}`
          setSnackbar({
            message: __('Duplicated successfully'),
            notificationType: SnackbarNotificationType.success,
            actionText: __('Go to duplicated task'),
            actionTextActions: [
              () => {
                window.open(newUrl, '_blank')
                trackEvent('Navigated to duplicated task from snackbar', { hashid: newHashid })
              },
            ],
            persist: true,
          })
          window.open(newUrl, '_blank')
          trackEvent('Duplicated task from "Share" dialog', { original_hashid: hashid, duplicate_hashid: newHashid })
        } catch (error) {
          console.error('Error duplicating task:', error)
          setSnackbar({
            message: __('Failed to duplicate task'),
            notificationType: SnackbarNotificationType.error,
          })
        }
      },
    ],
  })
}

onMounted(() => {
  window.history.replaceState = new Proxy(window.history.replaceState, {
    apply: (target, thisArg, args: [data: any, unused: string, url?: string | URL | null | undefined]) => {
      const result = target.apply(thisArg, args)
      updateHasPromptId()
      return result
    },
  })
  updateHasPromptId()
})
</script>

<template>
  <div class="flex items-center justify-center h-full gap-6">
    <!-- Desktop View -->
    <div class="hidden lg:flex items-center gap-6">
      <BaseIconButton
        v-if="canShare"
        class="h-10 flex items-center justify-center cursor-pointer text-lg leading-[30px]"
        :dark-mode="true"
        @click="share"
      >
        <p class="flex items-center justify-center gap-2">
          <BaseIcon name="interface_share" :size="20" />
          {{ __('Share') }}
        </p>
      </BaseIconButton>
      <BaseIconButton
        v-if="canDuplicate"
        class="h-10 flex items-center justify-center cursor-pointer text-lg leading-[30px]"
        :dark-mode="true"
        @click="duplicate"
      >
        <p class="flex items-center justify-center gap-2">
          <BaseIcon name="interface_duplicate" :size="20" />
          {{ __('Duplicate') }}
        </p>
      </BaseIconButton>
      <PromptHistoryButton v-if="currentPath !== '/history'" />
      <AuthButton :loaded-locale="loadedLocale" />
    </div>

    <!-- Mobile View -->
    <div class="lg:hidden">
      <BasePopover popover-id="mobile-menu" :backdrop="BasePopoverBackdropStyle.Light" ref="popoverRef">
        <template #button="slotProps">
          <BaseIconButton
            id="mobile-menu-button"
            class="p-1 flex items-center justify-center cursor-pointer font-medium"
            v-bind="slotProps"
            :dark-mode="true"
            ref="menuButtonRef"
          >
            <BaseIcon :size="32" name="interface_more" />
          </BaseIconButton>
        </template>

        <template #popover>
          <div
            ref="menuPopoverRef"
            :style="floatingStyles"
            class="flex flex-col w-64 px-3 py-1 rounded-xl bg-white dark:bg-black border-2 border-black dark:border-white select-none font-sans"
          >
            <BaseRows :aria-label="__('Menu options')" :rows="menuRows" @select-row="handleMenuSelectRow" />
          </div>
        </template>
      </BasePopover>

      <!-- Invisible buttons for click handling -->
      <div
        :class="[
          'absolute opacity-0',
          { 'pointer-events-auto': isHistoryOrAuthTriggered, 'pointer-events-none': !isHistoryOrAuthTriggered },
        ]"
      >
        <PromptHistoryButton
          v-if="currentPath !== '/history'"
          ref="promptHistoryButtonRef"
          @popover-close="isHistoryOrAuthTriggered = false"
        />
        <AuthButton
          ref="authButtonRef"
          :loaded-locale="loadedLocale"
          @popover-close="isHistoryOrAuthTriggered = false"
        />
      </div>
    </div>
  </div>
</template>
