<script lang="ts" setup>
import { fetchClaimPrompts } from '@/api/promptHistory'
import BaseButton from '@/components/library/BaseButton.vue'
import BaseContainedButton, {
  BaseContainedButtonColorScheme,
  BaseContainedButtonSizePreset,
} from '@/components/library/BaseContainedButton.vue'
import BaseDivider from '@/components/library/BaseDivider.vue'
import BaseIcon from '@/components/library/BaseIcon.vue'
import BaseIconButton from '@/components/library/BaseIconButton.vue'
import BasePopover from '@/components/library/BasePopover.vue'
import type { HistoryEntry } from '@/pages/api/prompt'
import { trackEvent } from '@/utils/client/analytics'
import { usePromptHistoryStore } from '@/utils/client/store/promptHistoryStore'
import { useSessionDataStore } from '@/utils/client/store/sessionDataStore'
import type { Hashid } from '@/utils/server/hashid'
import { mapGenerationTypeToIcon, mapGenerationTypeToTitle } from '@/utils/shared/generationTypeMapping'
import { __ } from '@/utils/shared/intl'
import { computed, ref, watch } from 'vue'

const emit = defineEmits(['popover-close'])

const popoverRef = ref<InstanceType<typeof BasePopover> | null>(null)
const { prompts, fetchPrompts } = usePromptHistoryStore()
const { isLoggedIn, hasAnonymousUserPrompts, currentAnonymousUserPromptIds, clearAnonymousUserPromptIds } =
  useSessionDataStore()

function triggerClick() {
  popoverRef.value?.showPopover()
}

function onPopoverHide() {
  emit('popover-close')
  clearHighlightedPrompts()
}

defineExpose({
  triggerClick,
})

function getRelativeTime(date: Date) {
  // Create dates using only the date portion, not time
  const dateToCompare = new Date(date).setHours(0, 0, 0, 0)
  const now = new Date().setHours(0, 0, 0, 0)
  const diffTime = Math.abs(now - dateToCompare)
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

  if (diffDays === 0) {
    return __('Today')
  } else if (diffDays === 1) {
    return __('Yesterday')
  } else if (diffDays < 30) {
    return __('%{numberOfDays}d ago', { numberOfDays: String(diffDays) })
  } else {
    return new Date(date).toLocaleDateString()
  }
}

const isHomePage = window.location.pathname === '/'

const highlightedPrompts = ref<Hashid[]>([])

const recentPrompts = computed(() => prompts.value.slice(0, 5))

function openHistoryAndHighlightPrompts(hashids: Hashid[]) {
  highlightedPrompts.value = [...hashids]
  popoverRef.value?.showPopover()
}

function clearHighlightedPrompts() {
  highlightedPrompts.value = []
}

function trackOpenHistory(): void {
  trackEvent('Opened recent tasks popover')
}

function trackViewAllClick(): void {
  trackEvent('Clicked "View all" in recent tasks popover')
}

function trackPromptClick(prompt: HistoryEntry): void {
  trackEvent('Clicked task in recent tasks popover', { prompt_hashid: prompt.hashid })
}

watch(
  [hasAnonymousUserPrompts, isLoggedIn],
  async () => {
    if (hasAnonymousUserPrompts.value && isLoggedIn.value) {
      const response = await fetchClaimPrompts(
        {
          hashids: Array.from(currentAnonymousUserPromptIds.value),
        },
        openHistoryAndHighlightPrompts,
      )
      // after claiming prompts, clear the anonymous user prompt ids and re-fetch the prompts
      if (response.data.hashids.length > 0) {
        clearAnonymousUserPromptIds()
        await fetchPrompts()
      }
    }
  },
  { immediate: true },
)

// Initial fetch and watch for login state changes
watch(
  isLoggedIn,
  async () => {
    await fetchPrompts()
  },
  { immediate: true },
)
</script>

<template>
  <div>
    <BasePopover ref="popoverRef" popover-id="prompt-history-menu" @hide="onPopoverHide">
      <template #button="slotProps">
        <BaseContainedButton
          v-if="isHomePage"
          class="h-9 w-9 flex items-center justify-center !p-0 rounded-xl"
          v-bind="slotProps"
          :color-scheme="BaseContainedButtonColorScheme.Secondary"
          :size-preset="BaseContainedButtonSizePreset.H32px"
          @click="trackOpenHistory"
        >
          <BaseIcon :size="20" name="history_filled" />
        </BaseContainedButton>
        <BaseIconButton
          v-else
          class="p-1 flex items-center justify-center cursor-pointer font-medium"
          v-bind="slotProps"
          :dark-mode="true"
          @click="trackOpenHistory"
        >
          <BaseIcon :size="24" name="history_outline" />
        </BaseIconButton>
        <!-- v-bind="slotProps"
          :color-scheme="BaseContainedButtonColorScheme.Secondary"
          @click="() => {}"
          :class="['flex items-center justify-center', 'rounded-xl !px-2 !h-9']"
        >
          <BaseIcon :size="20" name="history_outline" />
        </BaseContainedButton> -->
      </template>

      <template #popover>
        <div
          :class="[
            // Layout
            'flex',
            'flex-col',
            'w-80',
            'rounded-2xl',
            // Positioning
            'absolute',
            'top-16',
            'end-[calc(50%-160px)] sm:end-[50px]',
            // Spacing
            'px-2 py-1',
            // Border
            'border-2',
            'border-black',
            // Background
            'bg-gray-100',
            'dark:bg-gray-800',
            'opacity-90 backdrop-blur-md',
            // Text
            'text-black',
            'dark:text-white',
          ]"
        >
          <div :class="['flex', 'flex-col', 'gap-1']">
            <div class="flex justify-between pt-2 px-2">
              <h3 class="text-sm text-gray-900 text-opacity-70 font-semibold">
                {{ __('Recent tasks') }}
              </h3>
              <BaseButton
                class="text-sm text-gray-900 hover:text-gray-900/70 font-semibold"
                href="/history"
                @click="trackViewAllClick"
              >
                {{ __('View all') }}
              </BaseButton>
            </div>
            <div class="flex flex-col overflow-y-auto">
              <div
                v-for="(prompt, i) in recentPrompts"
                :key="prompt.hashid"
                :class="['px-1', 'pt-1', i === recentPrompts.length - 1 && 'pb-1']"
              >
                <BaseButton
                  :href="`/prompt/${prompt.hashid}`"
                  :class="[
                    'flex flex-row items-center justify-start gap-2 p-1',
                    'hover:bg-gray-200 rounded-2xl',
                    'focus-visible:ring-1',
                    'focus-visible:ring-violet-500',
                    'focus-visible:ring-offset-0',
                    'focus-visible:outline-none',
                  ]"
                  target="_blank"
                  @click="trackPromptClick(prompt)"
                >
                  <div :class="['flex flex-row items-center justify-start gap-2 overflow-hidden  w-full']">
                    <div class="flex items-center justify-center">
                      <BaseIcon :name="mapGenerationTypeToIcon(prompt.prompt_type)" />
                    </div>
                    <div class="min-w-0">
                      <p class="truncate text-sm font-semibold text-ellipsis overflow-hidden whitespace-nowrap">
                        {{ prompt.prompt_title }}
                      </p>
                      <div class="flex flex-row items-center justify-start gap-1">
                        <p class="text-sm">
                          {{ mapGenerationTypeToTitle(prompt.prompt_type) }}
                        </p>
                        <p class="text-sm">•</p>
                        <p class="text-sm font-normal">{{ getRelativeTime(prompt.updated_at) }}</p>
                      </div>
                    </div>
                    <div
                      v-if="highlightedPrompts.includes(prompt.hashid)"
                      class="flex items-center justify-center font-sans uppercase ms-auto"
                    >
                      <p
                        class="rounded-md py-1 px-1 leading-none text-sm font-semibold text-white bg-green-600 animate-pulse"
                      >
                        {{ __('New') }}
                      </p>
                    </div>
                  </div>
                </BaseButton>
                <BaseDivider v-if="i !== recentPrompts.length - 1" class="mt-1" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </BasePopover>
  </div>
</template>
