<script setup lang="ts">
import { SnackbarNotificationType } from '@/utils/client/store/snackbarStore'
import type { AppColorName } from '@/utils/shared/app_colors'
import { APP_COLORS } from '@/utils/shared/app_colors'
import { onMounted, ref } from 'vue'
import BaseButton from './BaseButton.vue'
import BaseIcon from './BaseIcon.vue'

withDefaults(
  defineProps<{
    /**
     * Unique id associated with the snackbar
     */
    uid: string
    /**
     * Displayed message of the snackbar
     */
    message: string
    /**
     * Type of snackbar notification
     */
    notificationType?: SnackbarNotificationType
    /**
     * Icon to display on the snackbar
     */
    iconName?: string
    /**
     * Text for clickable action
     */
    actionText?: string
    shouldTruncate?: boolean
    xCloseIcon?: boolean
  }>(),
  {
    notificationType: SnackbarNotificationType.success,
    iconName: undefined,
    actionText: undefined,
    shouldTruncate: false,
    xCloseIcon: false,
  },
)

const emit = defineEmits<{
  (name: 'click', event: MouseEvent): void
  (name: 'action-text-click', event: MouseEvent): void
}>()

const actionTextClick = (event: MouseEvent): void => {
  emit('action-text-click', event)
}

const snackbarColor = ref<string | null>(null)
const snackbarShadowColor = ref<string | null>(null)

onMounted(() => {
  const appColor = (window as unknown as { $appColor: AppColorName }).$appColor
  if (appColor != null) {
    snackbarColor.value = APP_COLORS[appColor].accent
    snackbarShadowColor.value = APP_COLORS[appColor].shadow
  } else {
    snackbarColor.value = '#E09DFF'
    snackbarShadowColor.value = '#000'
  }
})
</script>

<template>
  <div>
    <div class="relative flex items-center" :class="{ 'w-max': !actionText }">
      <BaseButton
        :class="[
          'snackbar-button',
          // Layout
          'flex',
          'items-center',

          // Shape
          'w-max',
          'max-w-[calc(100vw-64px)]',
          'py-2',
          !actionText ? 'px-3.5' : 'ps-3.5 pe-1',
          'rounded-xl',

          // Misc
          'font-sans',
          'pointer-events-auto',
          'transition-all',
          'outline-none',

          // Color
          'border-2 border-black',
          'shadow-[0px_4px_8px_0px_rgba(0,0,0,0.25)]',
        ]"
        :style="
          snackbarColor && {
            backgroundColor: snackbarColor,
          }
        "
        @click="$emit('click', $event)"
      >
        <BaseIcon v-if="xCloseIcon" class="me-3" :name="'close_filled'" :size="16" />
        <div :class="[shouldTruncate && 'flex overflow-hidden', 'text-sm']">
          <span :class="['break-words', shouldTruncate && 'truncate', 'text-black font-semibold']">{{ message }}</span>
        </div>
        <p v-if="actionText" aria-hidden="true" class="invisible ms-5 px-3 text-sm font-semibold">{{ actionText }}</p>
      </BaseButton>
      <BaseButton
        v-if="actionText"
        :class="[
          'action-button',
          // Position
          'absolute',
          'end-1.5',
          'z-10',

          // Shape
          'px-3',
          'py-1',
          'rounded-lg',

          // Misc
          'text-sm',
          'font-semibold',
          'pointer-events-auto',
          'outline-none',

          // Color
          'bg-white/40',
          'hover:bg-white/60',
          'active:bg-white/20',
          'focus-visible:ring-2',
          'focus-visible:ring-black',
        ]"
        @click.stop.prevent="actionTextClick"
      >
        {{ actionText }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>
.snackbar-button:has(+ .action-button:focus-visible) {
  @apply border-black/50;
}
</style>
